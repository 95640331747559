import React from 'react';
import {Nav, Col, Row, Container} from 'react-bootstrap';
import games from '../games';

import styles from "./footer.module.scss";

class Footer extends React.Component {
  render() {
    const firstGameCol = games.slice(0, 10);
    const secondGameCol = games.slice(10, 20);
    const thirdGameCol = games.slice(20, games.length);

    return (
      <footer className={`${styles.footer} mt-5`}>
        <Nav className={`p-5`}>
          <Container>
            {/* <Row className={`d-flex justify-content-end`}> */}
            <Row>
              <Col xs={6} md={3} className={`mt-3 mt-md-0`}>
                <Nav.Item>
                  {firstGameCol.map(game =>
                  <a
                    href={`/?game=${game.slug}`}
                    className='nav-link'
                    key={game.slug}
                  >
                    {game.name}
                  </a>)}
                </Nav.Item>
              </Col>
              <Col xs={6} md={3} className={`mt-3 mt-md-0`}>
                <Nav.Item>
                  {secondGameCol.map(game =>
                  <a
                    href={`/?game=${game.slug}`}
                    className='nav-link'
                    key={game.slug}
                  >
                    {game.name}
                  </a>)}
                </Nav.Item>
              </Col>
              <Col xs={6} md={3} className={`mt-3 mt-md-0`}>
                <Nav.Item>
                  {thirdGameCol.map(game =>
                    <a
                      href={`/?game=${game.slug}`}
                      className='nav-link'
                      key={game.slug}
                    >
                      {game.name}
                    </a>)}
                </Nav.Item>
              </Col>
              <Col xs={6} md={3} className={`mt-3 mt-md-0`}>
                <Nav.Item>
                  <a href={`/about`} className='nav-link'>About</a>
                  <a href={`/contact`} className='nav-link'>Contact</a>
                  <a href={`/privacy`} className='nav-link'>Privacy</a>
                </Nav.Item>
              </Col>
            </Row>
          </Container>
        </Nav>
        <section className={styles.footerCopyright}>
          <Container>
                  <p className={`pb-3 m-0`}>Copyright © {new Date().getFullYear()} Mouse Sensitivity Converter | v1.0.1</p>
          </Container>
        </section>
      </footer>
    );
  }
}

Footer.defaultProps = {
  games: []
};

export default Footer;