import React from 'react';
import PropTypes from "prop-types";
import Header from './header';
import Footer from './footer';
import SEO from '../components/seo';

import './layout.module.scss';


const Layout = ({ children, title, description, keywords }) => {
  return (
    <>
      <SEO title={title} description={description} keywords={keywords}/>
      <div className={`site`}>
        <Header/>
        <main className={`site-content`}>
          { children }
        </main>
        <Footer/>
      </div>
    </>
  );
};

Layout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.array,
};

Layout.defaultProps = {
  title: '',
  description: '',
  keywords: [],
};

export default Layout;