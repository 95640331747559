const games = [
  {
    name: "Apex Legends",
    slug: "apex-legends",
    vfov: 73.74,
    type: "normal",
    hfov: 106.26,
    divisor: 2.604354,
    description: "Mouse sensitivity converter for Apex Legends. Apex Legends is a free-to-play battle royale game developed by Respawn Entertainment and published by Electronic Arts. It was released on February 4, 2019. The gameplay of Apex fuses elements from a variety of video games, including Respawn's own Titanfall series, battle royale games, class-based shooters, and those with evolving narratives. Approximately sixty players are pitted against each other on an island in squads of three, with one player controlling where their squad lands.",
    url: "https://www.ea.com/en-ca/games/apex-legends",
    logo: "/images/apex_legends-logo.png"
  },
  {
    name: "Battalion 1944",
    slug: "battalion-1944",
    vfov: 63.09,
    hfov: 95,
    type: "normal",
    divisor: 3.2744,
    description: "Mouse sensitivity converter for Battalion. Battalion recaptures the core of classic competitive shooters & refines the ‘classic’ FPS feel for the next generation. Focusing on 5v5 infantry combat, where precise flick shots with your Kar98, covering fire with your Thompson and fluid movement are key to clutching every round for your team. The game was announced via a Kickstarter campaign on February 3, 2016. The game is set during World War II and runs on Unreal Engine 4.",
    url: "https://battaliongame.com/",
    logo: "/images/battalion_1944-logo.png"
  },
  {
    name: "Battlefield V",
    slug: "battlefield-v",
    vfov: 55,
    hfov: 85.57,
    type: "linear",
    a: 40,
    c: 0.2,
    description: "Mouse sensitivity converter for Battlefield V. Battlefield V is a first-person shooter video game developed by EA DICE and published by Electronic Arts. Battlefield V is the sixteenth installment in the Battlefield series. It was released on November 20, 2018. The series goes back to its roots with a portrayal of World War 2 and focuses extensively on party-based features and mechanics, scarcity of resources, and removing 'abstractions' from game mechanics to increase realism.",
    url: "https://www.ea.com/en-gb/games/battlefield/battlefield-5",
    logo: "/images/battlefield_v-logo.jpg"
  },
  {
    name: "Borderlands 3",
    slug: "borderlands-3",
    vfov: 58.72,
    hfov: 90,
    type: "normal",
    divisor: 8,
    description: "Mouse sensitivity converter for Borderlands 3. Borderlands 3 is an action role-playing first-person shooter video game that is the sequel to 2012's Borderlands 2, and the fourth main entry in the Borderlands series. Borderlands 3 is a loot-driven first-person shooter. Players, either playing alone or in parties up to four people, make a character from one of four classes available, and take on various missions given out by non-playable characters (NPCs) and at bounty boards to gain experience, in-game monetary rewards, and reward items. Players can also gain these items by defeating enemies throughout the game.",
    url: "https://borderlands.com/",
    logo: "/images/borderlands-3.jpg"
  },
  {
    name: "Borderlands 2",
    slug: "borderlands-2",
    vfov: 58.72,
    hfov: 90,
    type: "normal",
    divisor: 8,
    description: "Mouse sensitivity converter for Borderlands 2. Borderlands 2 is an action role-playing first-person shooter video game developed by Gearbox Software and published by 2K Games. It is the second game in the Borderlands series and the sequel to 2009's Borderlands.",
    url: "https://borderlands.com/",
    logo: "/images/borderlands-2.jpeg"
  },
  {
    name: "Call of Duty: Black Ops 4",
    slug: "call-of-duty-black-ops-4",
    vfov: 58.72,
    hfov: 90,
    type: "normal",
    divisor: 8.67303,
    description: "Mouse sensitivity converter for Call of Duty. Call of Duty: Black Ops 4. Call of Duty: Black Ops 4 is a multiplayer first-person shooter developed by Treyarch and published by Activision. It was released on October 12, 2018. Call of Duty: Black Ops 4 features fluid Multiplayer combat and the biggest Zombies offering ever with three full undead adventures at launch.",
    url: "https://www.callofduty.com/ca/en/blackops4",
    logo: "/images/call_of_duty-logo.jpg"
  },
  {
    name: "CS:GO",
    slug: "cs-go",
    vfov: 73.74,
    hfov: 106.26,
    type: "normal",
    divisor: 2.604354,
    description: "Mouse sensitivity converter for CS:GO. Counter-Strike: Global Offensive is a multiplayer first-person shooter video game developed by Hidden Path Entertainment and Valve Corporation. CS: GO expands upon the team-based action gameplay that it pioneered when it was launched 19 years ago.  CS: GO features new maps, characters, weapons, and game modes, and delivers updated versions of the classic CS content.",
    url: "https://blog.counter-strike.net",
    logo: "/images/cs_go-logo.jpg"
  },
  {
    name: "Destiny 2",
    slug: "destiny-2",
    vfov: 51.8,
    hfov: 81.6,
    type: "normal",
    divisor: 8.6806,
    description: "Mouse sensitivity converter for Destiny 2. Destiny 2 is an online-only multiplayer first-person shooter video game developed by Bungie and published by Activision. It was released in September 2017. It is an open-world first-person shooter. Its gameplay focuses on updates to new campaign modes, multiplayer maps, weapons.",
    url: "https://www.bungie.net/",
    logo: "/images/destiny2-logo.jpg"
  },
  {
    name: "Far Cry",
    slug: "far-cry",
    vfov: 77,
    hfov: 109.47,
    type: "normal",
    divisor: 2.604354,
    description: "Mouse sensitivity converter for Far Cry. Far Cry is a franchise of first-person shooter video games, all of which have been published by Ubisoft. A tropical paradise seethes with hidden evil in Far Cry, a  detailed action shooter that pushes the boundaries of combat to new levels.",
    url: "https://far-cry.ubisoft.com/game/en-ca/home",
    logo: "/images/farcry5-logo.png"
  },
  {
    name: "Fortnite",
    slug: "fortnite",
    vfov: 56,
    hfov: 80,
    type: "normal",
    divisor: 0.103145,
    description: "Mouse sensitivity converter for Fortnite. Fortnite Battle Royale is a player-versus-player battle royale game for up to 100 players, allowing one to play alone, in a duo, or in a squad (usually consisting of three or four players). Developed by Epic Games, it was released in 2017.",
    url: "https://www.epicgames.com/fortnite",
    logo: "/images/fortnite-logo.png"
  },
  {
    name: "Overwatch",
    slug: "overwatch",
    vfov: 70.53,
    hfov: 103,
    type: "normal",
    divisor: 8.6806,
    description: "Mouse sensitivity converter for Overwatch. Overwatch is a team-based multiplayer first-person shooter developed and published by Blizzard Entertainment and released on May 24, 2016. It is a vibrant team-based shooter set on a near-future earth. Every match is a  6v6 battle between a cast of unique heroes, each with their own abilities.",
    url: "https://playoverwatch.com",
    logo: "/images/overwatch-logo.jpg"
  },
  {
    name: "Paladins",
    slug: "paladins",
    vfov: 67.67,
    type: "normal",
    hfov: 100,
    divisor: 6.258,
    description: "Mouse sensitivity converter for Paladins. Paladins: Champions of the Realm is a free-to-play online shooter video game by Hi-Rez Studios. The game was developed by Evil Mojo Games, an internal studio of Hi-Rez, and was released in 2018. Paladins: Champions of the Realm is the new Free-to-Play objective-based team first-person shooter from the makers of SMITE.",
    url: "https://www.paladins.com/",
    logo: "/images/paladins-logo.jpg"
  },
  {
    name: "PUBG",
    slug: "player-unknown-battlegrounds",
    vfov: 59,
    hfov: 90,
    type: "exponential",
    // "divisor": 0.025786
    a: 0.07757954979,
    b: 1.047116116,
    description: "Mouse sensitivity converter for PUBG. PlayerUnknown's Battlegrounds is an online multiplayer battle royale game developed and published by PUBG Corporation, a subsidiary of South Korean video game company Bluehole. Players drop onto an island and fight to be the last one left standing. There are currently three maps: Erangel, Sanhok, and Miramar. You can choose whether to play solo, duo, or with a squad of up to four players. Games load in with up to 100 players in total.",
    url: "https://www.pubg.com",
    logo: "/images/pubg-logo.jpg"
  },
  {
    name: "Quake Live",
    slug: "quake-live",
    vfov: 83.58,
    hfov: 115.63,
    type: "normal",
    divisor: 2.60417,
    description: "Mouse sensitivity converter for Quake Live. Quake Live is a first-person shooter video game by id Software. It is an updated version of Quake III Arena that was originally designed as a free-to-play game launched via a web browser plug-in. On September 17, 2014, the game was re-launched as a standalone title on Steam.",
    url: "https://www.quakelive.com/",
    logo: "/images/quake_live-logo.png"
  },
  {
    name: "Team Fortress 2",
    slug: "team-fortress-2",
    vfov: 59.84,
    hfov: 91.31,
    type: "normal",
    divisor: 2.604354,
    description: "Mouse sensitivity converter for Team Fortress 2. Team Fortress 2 is a team-based multiplayer first-person shooter video game developed and published by Valve Corporation. It is the sequel to the 1996 mod Team Fortress for Quake and its 1999 remake, Team Fortress Classic.",
    url: "http://www.teamfortress.com/",
    logo: "/images/team_fortress_2-logo.png"
  },
  {
    name: "Titanfall 2",
    slug: "titanfall-2",
    vfov: 55.41,
    hfov: 86.07,
    type: "normal",
    divisor: 2.60437,
    description: "Mouse sensitivity converter for Titanfall 2. Titanfall 2 is a first-person shooter video game, developed by Respawn Entertainment and published by Electronic Arts. A sequel to 2014's Titanfall, the game was released worldwide on October 28, 2016.  Featuring the debut of a singleplayer campaign and a deeper multiplayer experience.",
    url: "https://www.ea.com/en-ca/games/titanfall",
    logo: "/images/titanfall2-logo.jpg"
  },
  {
    name: "Tom Clancy's Rainbow 6: Siege",
    slug: "rainbow-6-siege",
    vfov: 60,
    hfov: 91.49,
    type: "normal",
    divisor: 10,
    description: "Mouse sensitivity converter for R6S. Tom Clancy's Rainbow Six Siege is a tactical shooter video game developed by Ubisoft Montreal and published by Ubisoft. It was released on December 1, 2015. The game puts heavy emphasis on environmental destruction and cooperation between players.",
    url: "https://rainbow6.ubisoft.com",
    logo: "/images/rainbox_six_siege-logo.webp"
  },
  {
    name: "Tom Clancy's The Division 2",
    slug: "the-division-2",
    vfov: 43,
    hfov: 70.01,
    type: "linear",
    a: 0.078,
    c: 0.2,
    description: "Mouse sensitivity converter for Tom Clancy's The Division 2. Tom Clancy's The Division 2 is an online action role-playing video game developed by Massive Entertainment and published by Ubisoft. Seven months have passed since a deadly virus hit New York City. The Division 2 is a true RPG that offers more variety in missions and challenges, a new end-game, and fresh innovations to engage players.",
    url: "https://tomclancy-thedivision.ubisoft.com",
    logo: "/images/the_division_2-logo.jpg"
  },
  {
    name: "Ring of Elysium",
    slug: "ring-of-elysium",
    vfov: 55,
    hfov: 85.57,
    type: "normal",
    divisor: 56,
    description: "Mouse sensitivity converter for Ring of Elysium. Ring of Elysium is a free-to-play, multiplayer online battle royale game developed by Tencent Games and published by Tencent Games and Garena. Survivors must stay ahead of the approaching snowstorm while eliminating competition.",
    url: "https://www.ringofelysiumonline.com/",
    logo: "/images/ring_of_elysium-logo.png"
  },
  {
    name: "Warface",
    slug: "warface",
    vfov: 59.84,
    hfov: 91.31,
    type: "normal",
    divisor: 17.2,
    description: "Mouse sensitivity converter for Warface. Warface is a free-to-play online first-person shooter developed by Crytek Kiev and co-produced by Crytek Seoul.  5 player Co-op, new daily content, PVP and intense tactical combat powered by CRYENGINE.",
    url: "https://www.warface.com",
    logo: "/images/warface-logo.jpg"
  },
  {
    name: "1v1.LOL",
    slug: "1v1-lol",
    vfov: 50.534016,
    type: "normal",
    hfov: 80,
    divisor: 9.5,
    description: "1v1.LOL is a free Third-Person shooter that allows you to practice and train your in-game skills for fortnite, but in a more controlled environment.",
  },
  {
    name: "7 Days to Die",
    slug: "7-days-to-die",
    vfov: 70,
    type: "normal",
    hfov: 102.447858,
    divisor: 0.11459156,
    description: "7 Days to Die is an open-world game that is a unique combination of first person shooter, survival horror, tower defense, and role-playing games. Play the definitive zombie survival sandbox RPG that came first. Navezgane awaits!",
  },
  {
    name: "Aim Lab",
    slug: "aim-lab",
    vfov: 60,
    type: "normal",
    hfov: 91.492845,
    divisor: 1.145916,
    description: "Convert your mouse sensitivity for Aim Lab.",
  },
  {
    name: "Black Squad",
    slug: "black-squad",
    vfov: 61,
    hfov: 92.64,
    type: "normal",
    divisor: 10.4,
    description: "Black Squad is a free-to-play military first-person-shooter. Players can master their skills and show off their strategies with a wide range of game maps, modes, and weapons to choose from. Join thousands of FPS players worldwide in one of the most played games on Steam!",
  },
  {
    "name": "Darwin Project",
    "slug": "darwin-project",
    "vfov": 58.72,
    "hfov": 90,
    "type": "linear",
    "a": 0.0305,
    "c": 0.3054,
    "divisor": 2.977076510866329,
    "description": "In Darwin Project, ten inmates must survive the elements, track each other, fight, craft tools, and use their social skills to be the last one standing. It's a unique take on battle royale with a Show Director to influence the match and Spectator Interactions to create a one-of-a-kind game show.",
  },
  {
    "name": "Doom Eternal",
    "slug": "doom-eternal",
    "vfov": 58.715507,
    "hfov": 90,
    "type": "normal",
    "divisor": 0.0190985485103132,
    "description": "Hell’s armies have invaded Earth. Become the Slayer in an epic single-player campaign to conquer demons across dimensions and stop the final destruction of humanity. The only thing they fear... is you.",
  },
  {
    "name": "Escape from Tarkov",
    "slug": "escape-from-tarkov",
    "vfov": 50,
    "hfov": 79.316879,
    "type": "normal",
    "divisor": 0.458366,
    "description": "Escape from Tarkov is a hardcore and realistic online first-person action RPG/Simulator with MMO features and a story-driven walkthrough",
  },
  {
    "name": "Gears 5",
    "slug": "gears",
    "vfov": 50.53,
    "hfov": 80,
    "type": "linear",
    "a": 4.7,
    "c": -1.7,
    "description": "From one of gaming’s most acclaimed sagas, Gears is bigger than ever, with five thrilling modes and the deepest campaign yet. With all-out war descending, Kait Diaz breaks away to uncover her connection to the enemy and discovers the true danger to Sera – herself.",
  },
  {
    "name": "Halo",
    "slug": "halo",
    "vfov": 49.2,
    "hfov": 78.28,
    "type": "normal",
    "divisor": 0.3846153846,
    "description": "Halo: Reach is the first installment of Halo: The Master Chief Collection. Experience the heroic story of Noble Team, a group of Spartans, who through great sacrifice and courage, saved countless lives in the face of impossible odds. The planet Reach is humanity’s last line of defense between the encroaching Covenant and their ultimate goal, the destruction of Earth. If it falls, humanity will be pushed to the brink of destruction.",
  },
  {
    "name": "Hunt: Showdown",
    "slug": "hunt-showdown",
    "vfov": 55,
    "hfov": 85.56559,
    "type": "normal",
    "divisor": 1.333333,
    "description": "Hunt: Showdown is a first-person shooter survival horror video game developed and published by Crytek",
  },
  {
    "name": "Hyperscape",
    "slug": "hyperscape",
    "vfov": 65,
    "hfov": 97.114332,
    "type": "normal",
    "divisor": 10,
    "description": "Hyper Scape is a free-to-play Battle Royale FPS set in a futuristic virtual world, in 2054.",
  },
  {
    "name": "Roblox",
    "slug": "roblox",
    "vfov": 80,
    "hfov": 112.327252,
    "type": "normal",
    "divisor": 0.144,
    "description": "Roblox is an online game platform and game creation system that allows users to program games and play games created by other users. Founded by David Baszucki and Erik Cassel in 2004 and released in 2006, the platform hosts user-created games of multiple genres coded in the programming language Lua",
  },
  {
    "name": "Rogue Company",
    "slug": "rogue-company",
    "vfov": 42,
    "hfov": 68.621206,
    "type": "linear",
    "a": 2.55,
    "c": 1.0333,
    "description": "Rogue Company is a third-person tactical action shooter that puts the fate of the world in your hands. Suit up as one of the elite agents of Rogue Company, each with their own individual set of skills, and go to war in a variety of different game modes.",
  },
  {
    "name": "Valorant",
    "slug": "valorant",
    "vfov": 70.53,
    "hfov": 103,
    "type": "normal",
    "divisor": 0.819,
    "description": "Valorant is a  free-to-play multiplayer first-person shooter video game developed by Riot Games. A tactical 5v5 character-based shooter set in a near-future version of Earth",
  },

];

export default games;